import React from 'react';
import styled, { withTheme } from 'styled-components';
import ButtonTheme from './ButtonTheme';

const StyledCHeader = styled.div`
  color: var(--primaryLight-color);

  h2 {
    color: #ffffff;
  }
`;

interface Props {
  className?: string;
  title: string;
  description: string;
  enableThemeButton?: boolean;
}

function Top(props: Props) {
  const {
    // @ts-ignore
    enableThemeButton = false, title, description, className, theme: { name: themeName },
  } = props;

  return (
    <StyledCHeader className={className}>
      <h2>{title}</h2>
      <p>
        {description}
        {(enableThemeButton && themeName === 'horde') && <ButtonTheme />}
      </p>
    </StyledCHeader>
  );
}

// @ts-ignore
export default withTheme(Top);
