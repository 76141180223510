import React, { useContext } from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import storage from '../../common/utils/storage';
import { GlobalContext, themes } from '../../common/context/global-context';

const StyledButtonTheme = styled.span`
  animation: fadeInFromNone 0.4s ease-out;
  cursor: pointer;
  color: var(--primaryDark-color);
  margin-left: ${em(5)};

  &:hover {
    color: var(--secondaryLight-color);
  }
`;

export default function ButtonTheme() {
  const { setTheme } = useContext(GlobalContext);

  function onThemeHandler() {
    storage.set('horde', '');
    setTheme(themes.alliance);
  }

  return (
    <StyledButtonTheme onClick={onThemeHandler}>
      My heart sleeps in the Emerald Dream.
    </StyledButtonTheme>
  );
}
