import React, { Fragment } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { em } from 'polished';
import { capitalize, getLocationLink } from '../../../shared/utils';
import { FlightPoint, FlightType } from '../../../types/generated';

type LocationTitle = FlightType | 'other';

const LOCATION_TITLES = [['city', 'other'], ['dungeon', 'raid']];

const StyledLocations = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-flow: dense;
  grid-gap: ${em(30)};

  h1 {
    padding: ${em(5)} 0;
  }

  .location {
    display: block;
    font-size: ${em(17)};
    padding: ${em(3)} 0;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;

function Locations() {
  const data = useStaticQuery(graphql`
      query LocationsQuery {
          classic {
              getPoints {
                  location
                  continent
                  faction
                  globalLoc
                  mapLoc
                  hearthstone
                  type
                  class
              }
          }
      }
  `);

  const { getPoints: points } = data.classic;
  const pointsMapped = points.reduce((prev: Map<LocationTitle, FlightPoint[]>, curr: FlightPoint) => {
    const type = curr.type || 'other';
    const arr = prev.get(type) || [];
    arr.push(curr);
    arr.sort((a, b) => (a.location).localeCompare(b.location));
    prev.set(type, arr);
    return prev;
  }, new Map());

  return (
    <StyledLocations>
      {
        LOCATION_TITLES.map((arr, index) => (
          <div key={`loc${index}`}>
            {
              arr.map((title) => (
                <Fragment key={title}>
                  <h1>{capitalize(title)}</h1>
                  {
                    Array.from(pointsMapped.get(title)).map((point: FlightPoint) => (
                      <Link className="location" to={getLocationLink(point.location)} key={point.location}>
                        <div>{point.location}</div>
                      </Link>
                    ))
                  }
                </Fragment>
              ))
            }
          </div>
        ))
      }
    </StyledLocations>
  );
}

export default Locations;
