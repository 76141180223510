import React from 'react';
import SEO from '../client/common/layout/SEO';
import Top from '../client/names/components/Top';
import Layout from '../client/common/layout/Layout';
import LocationsContent from '../client/locations/components/Locations';

export default function Locations({ location }) {
  return (
    <Layout mainClass="page-padding-height">
      <SEO
        title="Locations | WoW Classic"
        description="Ultimate tool for navigating vanilla Azeroth in WoW Classic with an interactive map of routes, flight paths and travel points."
        location={location}
      />
      <Top
        className="page-container page-padding-width"
        title="Travel Locations"
        description={`
          List of Dungeons, Raids, Capital Cities and Towns in World of Warcraft Classic.
        `}
      />
      <br />
      <div className="page-container page-padding-width">
        <LocationsContent />
      </div>
    </Layout>
  );
}
